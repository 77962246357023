import commonTitle from '@comparacar/lib/src/common-titles'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import React from 'react'
import { useRecoilValue } from 'recoil'

import BrandSelector from './BrandSelector'
import ImageSlider from './ImageSlider'
import PriceWarning from './PriceWarning'
import ShareButton from './ShareButton'
import AllWheelDrive from '../icons/AllWheelDrive'
import AndroidAutoIcon from '../icons/AndroidAuto'
import AppleCarPlayIcon from '../icons/AppleCarPlay'
import { getProvider } from '../state/brands'

interface iMobileProps {
  data: ResultCard
  hideOfferButton?: boolean
  isLoading?: boolean
}

const CardMobile: React.FC<iMobileProps> = ({ data, hideOfferButton, isLoading }) => {
  const theme = useTheme()
  const {
    id,
    location,
    images,
    title,
    subtitle,
    description,
    description2,
    price,
    brand,
    hasApplePlay,
    hasArmor,
    hasAndroidAuto,
    hasAllWheelDrive,
    tags,
    hidePrice,
    isAuthorizedPartner,
    updatedAt
  } = data
  const providerData = useRecoilValue(getProvider(brand))

  return (
    <Card id={`card-${id}`} sx={{ minWidth: 300 }}>
      <Box sx={{ height: '44vw', maxHeight: 165, width: '100%' }}>
        <ImageSlider title={`${data.version.name}`} images={images.origin} link={data.localLink} tags={tags} />
      </Box>
      <CardContent sx={{ paddingX: 2, paddingY: 3, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2.5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end'
          }}
        >
          {isLoading ? (
            <Skeleton animation="wave" height={36} width={70} />
          ) : (
            <BrandSelector width={70} height={36} name={providerData?.slug} isAuthorizedPartner={isAuthorizedPartner} />
          )}
        </Box>
        <Box sx={{ width: 'calc(100% - 70px)' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ flex: isLoading ? 0.8 : 'unset' }}>
              <Typography variant="subtitle1" color="text.primary" noWrap>
                {isLoading ? <Skeleton animation="wave" /> : title}
              </Typography>
              <Typography component="div" variant="caption" color="text.secondary" gutterBottom noWrap>
                {isLoading ? <Skeleton animation="wave" /> : location}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {isLoading ? <Skeleton animation="wave" /> : subtitle}
          </Typography>
          <Typography component="div" variant="caption" color="text.secondary">
            {isLoading ? <Skeleton animation="wave" /> : description}
          </Typography>
          {description2 && (
            <Typography component="div" variant="caption" color="text.secondary">
              {isLoading ? <Skeleton animation="wave" /> : description2}
            </Typography>
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <PriceWarning updatedAt={updatedAt}>
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ maxWidth: '95%', minWidth: '70%', width: '100%' }}
                minHeight="32px"
              >
                {isLoading ? <Skeleton animation="wave" /> : price}
              </Typography>
            </PriceWarning>
            <Box
              sx={{
                '& > *': {
                  ml: 1
                }
              }}
            >
              {isLoading ? (
                <Skeleton animation="wave" width="100px" />
              ) : (
                <>
                  {hasAllWheelDrive && <AllWheelDrive width="24" height="24" />}
                  {hasApplePlay && <AppleCarPlayIcon />}
                  {hasAndroidAuto && <AndroidAutoIcon />}
                  {hasArmor && <ShieldOutlinedIcon />}
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Stack direction="column" spacing={1} mt={2}>
          {isLoading ? (
            <Skeleton animation="wave" width="100px" height="36px" />
          ) : (
            <div>
              <Link href={data.localLink} passHref legacyBehavior>
                <Button variant="contained" startIcon={<RemoveRedEyeIcon />} fullWidth>
                  {commonTitle.seeOffer}
                </Button>
              </Link>
            </div>
          )}
          <ShareButton isButton title={title} link={data.localLink} />
        </Stack>
        <Box maxWidth={268} mt={1}>
          {hidePrice && (
            <Typography sx={{ fontSize: 10 }} color="text.primary">
              *A {brand} não revela preços antes de explicar os benefícios e serviços da assinatura.
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default CardMobile
